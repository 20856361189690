import React, { useContext, useEffect } from 'react';
import * as S from './BumpOfferPopup.styles';
import FunnelContext from '../../context/FunnelContext';
import { formatPrice } from '../../utils/formatPrice';

export const BumpOfferPopup = ({
  offers,
  popupIndex,
  setPopupIndex,
  locked,
  popupButtonText,
  popupButtonTextChecked,
  oneTimeText,
  listIcon = '/images/blue-heart.svg',
  handleButton
}) => {
  const {
    currentCategory,
    onetime,
    subscription,
    bumpOfferArray,
    pageDesign,
    currency
  } = useContext(FunnelContext);
  const priceSettings = { currencySymbol: currency?.symbol || '$' };

  const bumpoffers =
    currentCategory === `onetime`
      ? onetime?.bumpoffers
      : subscription?.bumpoffers;

  const closePopup = () => {
    setPopupIndex(-1);
    document.body.style.overflow = 'scroll';
  };

  useEffect(() => {
    const keyHandler = e => !locked && e.key === 'Escape' && closePopup();

    window.addEventListener('keyup', e => {
      keyHandler(e);
    });

    return () => {
      window.removeEventListener('keyup', keyHandler);
    };
  });

  const replaceTags = text =>
    text?.replaceAll('[display-title]', bumpoffers[popupIndex]?.display_title);
  const isSelected = bumpOfferArray.includes(popupIndex);

  if (popupIndex === -1) {
    return ``;
  }

  const {
    popupTitle = 'Pair your order with [display-title] for the perfect bundle!',
    images = [],
    description = '',
    keyPoints,
    ingredients = ''
  } = offers[popupIndex];

  return (
    <S.ModalContainer>
      <S.Overlay pageDesign={pageDesign}>
        <S.CloseButton pageDesign={pageDesign} onClick={closePopup}>
          <S.CartX src="/images/fa-cancel.svg" alt="close" />
        </S.CloseButton>
        <S.Title
          dangerouslySetInnerHTML={{
            __html: replaceTags(popupTitle)
          }}
        />
        <S.Content>
          <S.PopupBlock>
            <S.Carousel
              images={images}
              settings={{ showThumbs: false }}
              isFiltered={false}
            />
            <S.ItemInfo>
              <S.DisplayTitle>
                {bumpoffers[popupIndex]?.display_title}
              </S.DisplayTitle>
              <S.Price>
                <S.DiscountedPrice>
                  {formatPrice(
                    bumpoffers[popupIndex]?.checkoutData?.discounted_price,
                    priceSettings
                  )}
                </S.DiscountedPrice>
                &nbsp;
                <S.Percentage>
                  ({bumpoffers[popupIndex]?.discount_percentage}% Off)
                </S.Percentage>
              </S.Price>
              <S.DescriptionContainer>
                <S.Description
                  dangerouslySetInnerHTML={{
                    __html: replaceTags(description)
                  }}
                />
                <div>
                  <S.Button
                    onClick={() => {
                      handleButton(popupIndex);
                      closePopup();
                    }}
                    isSelected={isSelected}
                  >
                    {isSelected ? popupButtonTextChecked : popupButtonText}
                  </S.Button>
                  <S.OneTimeText>{oneTimeText}</S.OneTimeText>
                </div>
              </S.DescriptionContainer>
            </S.ItemInfo>
          </S.PopupBlock>
          <S.PopupBlock>
            <S.KeyPoints>
              {keyPoints?.map(({ point }) => (
                <S.KeyPointItem listIcon={listIcon} key={point}>
                  {point}
                </S.KeyPointItem>
              ))}
            </S.KeyPoints>
            <S.Ingredients>
              <S.DisplayTitle>Ingredients</S.DisplayTitle>
              <div>{ingredients}</div>
            </S.Ingredients>
          </S.PopupBlock>
        </S.Content>
      </S.Overlay>
    </S.ModalContainer>
  );
};

export default BumpOfferPopup;
