import styled from 'styled-components';

export const BumpOfferWrapper = styled.div`
  .carousel.carousel-slider {
    display: flex;
    flex-direction: column-reverse;

    & > .control-dots {
      position: static;
    }
  }
`;

export const BumpOffer = styled.div``;

export const BumpOfferItem = styled.div`
  padding: 8px;
  background: ${({ selected }) => (selected ? '#f8eaec' : '#ffffff')};
  border: ${({ selected }) =>
    selected ? '1px solid #bc2e3e' : '1px solid #ccd2e3'};
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 642px) {
    display: ${({ mobileCards }) =>
      mobileCards === 'slim' ? 'flex' : 'block'};
  }
`;

export const BumpOfferImage = styled.div`
  display: flex;
  position: relative;

  & > img {
    margin-inline: auto;

    @media (max-width: 375px) {
      max-width: ${({ mobileCards }) =>
        mobileCards === 'slim' ? '40px' : 'auto'};
      max-height: ${({ mobileCards }) =>
        mobileCards === 'slim' ? '40px' : 'auto'};
    }
  }

  @media (max-width: 642px) {
    margin: ${({ mobileCards }) => (mobileCards === 'slim' ? '0' : 'auto')};

    & > img {
      margin-top: ${({ mobileCards }) =>
        mobileCards === 'slim' ? '0' : '20px'};
    }
  }
`;

export const SaleBadge = styled.div`
  max-width: 38px;
  background: #bc2e3e;
  border-radius: 4px;
  padding: 2px 8px 0;
  font-family: 'Utopia Std', serif;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SaleBadgeAbsolute = styled(SaleBadge)`
  position: absolute;
  right: 0;
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 20px;
  height: 20px;
  border: ${({ selected }) =>
    selected ? '1px solid #bc2e3e' : '1px solid #ccd2e3'};
  border-radius: 4px;
  background: #ffffff;

  @media (max-width: 375px) {
    bottom: ${({ mobileCards }) => (mobileCards === 'slim' ? '0' : 'auto')};
  }
`;

export const DiscountPercentage = styled.p`
  margin: 0 auto;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #bc2e3e;

  @media (max-width: 642px) {
    display: ${({ mobileCards }) => (mobileCards === 'slim' ? 'none' : 'flex')};
  }
`;

export const DisplayTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: ${({ selected }) => (selected ? '#bc2e3e' : '#33498e')};

  @media (max-width: 375px) {
    min-height: 39px;
    margin-bottom: 2px;
  }

  @media (max-width: 642px) {
    margin: ${({ mobileCards }) =>
      mobileCards === 'slim' ? '0 0 2px' : '6px auto 1px'};
  }
`;

export const SaleBadgeMobile = styled(SaleBadge)`
  position: static;
  display: none;

  @media (max-width: 642px) {
    display: ${({ mobileCards }) => (mobileCards === 'slim' ? 'flex' : 'none')};
    margin: 3px auto 2px;
  }
`;

export const Price = styled.div`
  margin: 2px auto;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 14px;
`;

export const DiscountedPrice = styled.span`
  font-weight: 700;
`;

export const FullPrice = styled.span`
  font-weight: 400;
  text-decoration-line: line-through;
`;
