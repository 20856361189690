import styled from 'styled-components';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import Modal from '../Modal/Modal';

export const ModalContainer = styled(Modal)`
  position: absolute;
  background-color: #fff;
  padding: 15px;
  z-index: 1000;
  width: 35%;
  border-radius: 0.5em;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999;
  overflow-y: auto;
`;

export const Body = styled.div`
  padding-left: 10px;
  border-left: 1px solid #e26447;
  font-size: 14px;
  position: relative;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                        & a {
                            color: #BC2E3E!important;
                        }
                        border-left: 1px solid #BC2E3E;
                    `;
      default:
        return `
                    & a {
                        color: #e26447!important;
                    }
                    `;
    }
  }}
`;

export const Title = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  margin: 16px 50px 0 16px;

  & > p {
    margin: 0;
  }
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 100%;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  font-size: 30px;
  font-family: Neuzeit Grotesk, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    background-color:#BC2E3E;
                `;
      default:
        return `
                    background-color:#E26447;
                `;
    }
  }}
  & > span {
    display: block;
    margin: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
`;

export const PopupBlock = styled.div`
  display: flex;
  flex: 1;
  margin: 32px 16px;

  @media (max-width: 1000px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const Carousel = styled(ImageCarousel)`
  width: 50%;
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  margin-left: 32px;

  @media (max-width: 1000px) {
    margin-top: 40px;
    margin-left: 0;
    max-width: initial;
  }
`;

export const OneTimeText = styled.p`
  margin: 15px auto;
  font-family: 'Utopia Std', serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
`;

export const DisplayTitle = styled.div`
  margin-bottom: 11px;
  font-family: 'Utopia Std', serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #001c72;
`;

export const Price = styled.div`
  display: flex;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
`;

export const DiscountedPrice = styled.div`
  color: #001c72;
`;

export const Percentage = styled.div`
  color: #bc2e3e;
`;

export const DescriptionContainer = styled.div`
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 20px;
  }
`;

export const Description = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin: 20px 0;

  & > p {
    margin: 0;
  }

  @media (max-width: 1000px) {
    margin: 0 0 20px;
  }
`;

export const Button = styled.div`
  background: ${({ isSelected }) => (isSelected ? '#ffffff' : '#bc2e3e')};
  border-radius: 4px;
  padding: 15px 40px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  border: 1px solid #bc2e3e;
  color: ${({ isSelected }) => (isSelected ? '#bc2e3e' : '#ffffff')};
  cursor: pointer;

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? '#bc2e3e' : '#961423'};
    color: #ffffff
  }
;
}
`;

export const KeyPoints = styled.div`
  flex: 1;
`;

export const KeyPointItem = styled.li`
  list-style-type: none;
  padding-left: 25px;
  margin-bottom: 10px;
  background-image: ${({ listIcon }) =>
    `url(${listIcon ?? '/images/blue-heart.svg'})`};
  background-repeat: no-repeat;
  background-position: left 5px;
  background-size: 15px;
  font-family: 'Utopia Std', serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Ingredients = styled.div`
  flex: 1;
  line-height: 140%;

  @media (max-width: 1000px) {
    margin: 20px 0;
  }
`;

export const CartX = styled.img``;
