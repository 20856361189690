import styled from 'styled-components';

const mobileGap = '5px';
const gap = '10px';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  user-select: none;
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  filter: ${({ shadow }) => (shadow ? `drop-shadow(${shadow})` : 'initial')};
  touch-action: none;
`;

export const CarouselContent = styled.div`
  display: flex;
  gap: 10px;
  overflow: scroll;
  transition: all 0.2s;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  & > * {
    width: ${({ slidesVisible, width }) =>
      `calc(${width}% / ${slidesVisible} - ${gap})`};

    @media (max-width: 642px) {
      width: ${({ slidesVisible, mobileWidth }) =>
        `calc(${mobileWidth}% / ${slidesVisible} - ${mobileGap})`};
    }
  }
`;

export const TitleControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
export const Controls = styled.div`
  display: flex;
  justify-content: end;

  & > span {
    width: 24px;
    height: 24px;
  }
`;
export const Title = styled.div`
  font-family: 'Utopia Std', sans-serif;
  font-size: 20px;
`;

export const Arrow = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ active }) => (active ? '#e7e9f2' : '#d9d9d9')};
  cursor: ${({ active }) => (active ? 'pointer' : 'auto')};
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  border-radius: 50%;

  & > svg {
    width: 7px;
  }
`;

export const Left = styled(Arrow)`
  transform: rotate(180deg);
`;

export const Right = styled(Arrow)`
  margin-left: 8px;
`;

export const Indicators = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 16px;
  margin-top: 18px;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ selected }) => (selected ? '#001c72' : '#e6e8f1')};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
`;
