import styled from 'styled-components';
import Plus from '../../../static/images/plus.inline.svg';
import { SaleBadge } from './BumpOfferScrollV1.styles';

export const BumpOfferWrapper = styled.div`
  .carousel.carousel-slider {
    display: flex;
    flex-direction: column-reverse;

    & > .control-dots {
      position: static;
    }
  }
`;

export const Arrow = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ active }) => (active ? '#e7e9f2' : '#d9d9d9')};
  cursor: ${({ active }) => (active ? 'pointer' : 'auto')};
  opacity: ${({ active }) => (active ? 1 : 0.2)};
  border-radius: 50%;

  & > svg {
    width: 7px;
  }
`;

export const Left = styled(Arrow)`
  transform: rotate(180deg);
`;

export const Right = styled(Arrow)``;

export const BumpOffer = styled.div`
  user-select: none;
`;

export const BumpOfferItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 168px;
  background: ${({ selected }) => (selected ? '#f8eaec' : '#ffffff')};
  border: ${({ selected }) =>
    selected ? '1px solid #bc2e3e' : '1px solid #ccd2e3'};
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
`;

export const BumpOfferImageWrapper = styled.div`
  width: 100%;
  background: ${({ selected, backgroundColor }) =>
    selected ? '#f8eaec' : backgroundColor};
  padding: 8px;
`;

export const BumpOfferImage = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  & > picture > img {
    max-width: 56px !important;

    @media (max-width: 375px) {
      max-width: 60px !important;
    }
  }
`;

export const SaleBadgeAbsolute = styled(SaleBadge)`
  position: absolute;
  right: 0;
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  border: ${({ selected }) =>
    selected ? '1px solid #bc2e3e' : '1px solid #ccd2e3'};
  border-radius: 4px;
  background: #ffffff;
`;

export const BumpImage = styled.img`
  width: 70px !important;
  height: 70px;
`;

export const DisplayTitle = styled.p`
  margin: 5px 0 0;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: ${({ selected }) => (selected ? '#bc2e3e' : '#33498e')};
`;

export const DiscountPercentage = styled.p`
  margin: 3px 0 0;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #bc2e3e;
`;

export const Price = styled.div`
  margin: 5px 0 0;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: ${({ selected }) => (selected ? '#bc2e3e' : '#33498e')};
`;

export const DiscountedPrice = styled.span`
  font-weight: 700;
`;

export const FullPrice = styled.span`
  font-weight: 400;
  text-decoration-line: line-through;
`;

export const Button = styled.div`
  --main-margin: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - var(--main-margin) * 2);
  max-width: 200px;
  padding: 8px 20px;
  margin: var(--main-margin);
  border: 1px solid #bc2e3e;
  border-radius: 4px;
  background-color: ${({ selected }) => (selected ? '#f8eaec' : '#ffffff')};
  color: #bc2e3e;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
`;

export const ButtonText = styled.span`
  margin-top: 2px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
`;

export const PlusIcon = styled(Plus)`
  margin: 0 8px;
  transition: transform 0.2s ease-in-out;
  transform: ${({ selected }) => (selected ? 'rotate(45deg)' : 'rotate(0)')};

  & > path {
    fill: #bc2e3e;
  }
`;
