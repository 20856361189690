import React, { useContext, useState } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './BumpOfferScrollV2.styles';
import 'react-responsive-carousel/lib/styles/carousel.css';
import BumpCarousel from '../BumpCarousel/BumpCarousel';
import { formatPrice } from '../../utils/formatPrice';
import BumpOfferPopup from './BumpOfferPopup';
import Checkmark from '../../../static/images/checkmark.inline.svg';

const BumpOfferScroll = props => {
  const {
    offers,
    bumpOfferPrefixText,
    showSaleBadge,
    saleBadgeText,
    buttonText = 'ADD',
    buttonTextChecked = 'REMOVE',
    popupButtonText,
    popupButtonTextChecked,
    oneTimeText,
    listIcon,
    width,
    mobileWidth
  } = props;

  const [popupIndex, setPopupIndex] = useState(-1);
  const context = useContext(FunnelContext);

  const {
    currency,
    onetime,
    subscription,
    currentCategory,
    pageDesign,
    bumpOfferArray,
    setBumpOfferArray
  } = context;

  const priceSettings = { currencySymbol: currency?.symbol || '$' };

  const bumpoffers =
    currentCategory === `onetime`
      ? onetime?.bumpoffers
      : subscription?.bumpoffers;

  const handleButton = index => {
    const isSelected = bumpOfferArray?.includes(index);
    if (isSelected) {
      setBumpOfferArray(bumpOfferArray?.filter(item => item !== index));
    } else {
      setBumpOfferArray([...bumpOfferArray, index]);
    }
  };

  return (
    <S.BumpOfferWrapper>
      <BumpCarousel
        slidesVisible={2}
        showControls
        width={width}
        mobileWidth={mobileWidth}
        shadow={'0px 4px 20px rgba(0, 0, 0, 0.1)'}
      >
        {bumpoffers.map((item, i) => (
          <S.BumpOffer key={item?.checkoutData?.variant_id}>
            <S.BumpOfferItem
              key={item?.checkoutData?.variant_id}
              selected={bumpOfferArray?.includes(i)}
              onClick={() => handleButton(i)}
            >
              <S.BumpOfferImageWrapper
                selected={bumpOfferArray?.includes(i)}
                backgroundColor={offers[i]?.backgroundColor ?? '#ffffff'}
              >
                <S.BumpOfferImage pageDesign={pageDesign}>
                  {showSaleBadge && (
                    <S.SaleBadgeAbsolute>{saleBadgeText}</S.SaleBadgeAbsolute>
                  )}
                  <S.Checkbox selected={bumpOfferArray?.includes(i)}>
                    {bumpOfferArray?.includes(i) ? <Checkmark /> : null}
                  </S.Checkbox>
                  <S.BumpImage
                    alt="bump offer product"
                    loading="lazy"
                    src={item?.checkoutData.image}
                    width={56}
                    height={56}
                  />
                </S.BumpOfferImage>
              </S.BumpOfferImageWrapper>
              <S.DisplayTitle selected={bumpOfferArray?.includes(i)}>
                {item?.display_title}
              </S.DisplayTitle>
              <S.DiscountPercentage>
                {item?.discount_percentage}% Off
              </S.DiscountPercentage>
              <S.Price selected={bumpOfferArray?.includes(i)}>
                <S.DiscountedPrice>
                  {formatPrice(
                    item?.checkoutData?.discounted_price,
                    priceSettings
                  )}
                </S.DiscountedPrice>
                &nbsp;
                <S.FullPrice>
                  {formatPrice(item?.checkoutData?.price, priceSettings)}
                </S.FullPrice>
              </S.Price>
              <S.Button selected={bumpOfferArray?.includes(i)}>
                <S.ButtonText>
                  {bumpOfferArray?.includes(i) ? buttonTextChecked : buttonText}
                </S.ButtonText>
                <S.PlusIcon selected={bumpOfferArray?.includes(i)} />
              </S.Button>
            </S.BumpOfferItem>
          </S.BumpOffer>
        ))}
      </BumpCarousel>
      <BumpOfferPopup
        offers={offers}
        popupIndex={popupIndex}
        setPopupIndex={setPopupIndex}
        bumpOfferPrefixText={bumpOfferPrefixText}
        popupButtonText={popupButtonText}
        popupButtonTextChecked={popupButtonTextChecked}
        oneTimeText={oneTimeText}
        listIcon={listIcon}
        handleButton={handleButton}
      />
    </S.BumpOfferWrapper>
  );
};

export default BumpOfferScroll;
