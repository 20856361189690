import React, { useContext, useEffect, useRef, useState } from 'react';
import * as S from './BumpCarousel.styles';
import Chevron from '../../../static/images/chevron.inline.svg';
import FunnelContext from '../../context/FunnelContext';

const BumpCarousel = ({
  mobileSlidesAmount = 2,
  tabletSlidesAmount = 3,
  desktopSlidesAmount = 3,
  showControls = true,
  showIndicators = true,
  width = 100,
  mobileWidth = 100,
  shadow = 'none',
  showTitle = false,
  otpTitle = '3. Add Extras',
  subTitle = '3. Add extras to your subscription',
  children
}) => {
  const [windowSize, setWindowSize] = useState({});
  const [slidesVisible, setSlidesVisible] = useState(2);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children && children.length);

  const carouselContent = useRef(null);

  const { currentCategory } = useContext(FunnelContext);

  useEffect(() => {
    const { innerWidth, innerHeight } = window || {};
    setWindowSize({ innerWidth, innerHeight });
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth <= 730 && windowSize.innerWidth > 0) {
      setSlidesVisible(mobileSlidesAmount);
    } else if (windowSize.innerWidth <= 1100 && windowSize.innerWidth > 730) {
      setSlidesVisible(tabletSlidesAmount);
    } else {
      setSlidesVisible(desktopSlidesAmount);
    }
  }, [
    desktopSlidesAmount,
    mobileSlidesAmount,
    tabletSlidesAmount,
    windowSize.innerWidth
  ]);

  useEffect(() => {
    const { current: elem } = carouselContent;

    function onScroll() {
      const { scrollLeft, scrollWidth, offsetWidth } = elem;
      const oneElem = scrollWidth / length;
      const amountHidden = length - Math.round(offsetWidth / oneElem);
      const sectionHidden = (amountHidden * oneElem) / (length - 2);
      let i;

      if (scrollLeft === 0) {
        i = 0;
      } else if (scrollLeft + offsetWidth === scrollWidth) {
        i = length - 1;
      } else if (amountHidden % 2 !== 0) {
        i = Math.round(scrollLeft / sectionHidden);
      } else {
        i = Math.round((2 * scrollLeft - oneElem) / (2 * oneElem) + 1);
      }
      setCurrentIndex(i);
    }

    elem.addEventListener('scroll', onScroll);

    return () => {
      elem.removeEventListener('scroll', onScroll);
    };
  }, [length, children]);

  useEffect(() => {
    setLength(children && children.length);

    function handleWindowResize() {
      const { innerWidth, innerHeight } = window || {};
      setWindowSize({ innerWidth, innerHeight });
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [children]);

  const scrollTo = i => {
    const { current } = carouselContent;
    const { scrollWidth, offsetWidth } = current;
    const oneElem = Math.round(current.scrollWidth / length);
    const amountHidden = length - Math.round(offsetWidth / oneElem);

    if (i === 0) {
      current.scrollLeft = 0;
      return;
    } else if (amountHidden % 2 !== 0 && i === length - 2) {
      current.scrollLeft = scrollWidth - offsetWidth - 15;
      return;
    } else if (i === length - 1) {
      current.scrollLeft = scrollWidth - offsetWidth;
      return;
    }

    current.scrollLeft = oneElem / 2 + oneElem * (i - 1);
  };

  return (
    <S.Container>
      <S.TitleControlsWrapper>
        {showTitle ? (
          <>
            {' '}
            {currentCategory === `subscriptions` ? (
              <S.Title>{subTitle}</S.Title>
            ) : (
              <S.Title>{otpTitle}</S.Title>
            )}
          </>
        ) : (
          <div></div>
        )}
        {showControls ? (
          <S.Controls>
            <S.Left
              onClick={() => {
                if (currentIndex <= 0) return;
                scrollTo(currentIndex - 1);
              }}
              active={currentIndex > 0}
            >
              <Chevron />
            </S.Left>
            <S.Right
              onClick={() => {
                if (currentIndex >= length - 1) return;
                scrollTo(currentIndex + 1);
              }}
              active={currentIndex + 1 < length}
            >
              <Chevron />
            </S.Right>
          </S.Controls>
        ) : null}
      </S.TitleControlsWrapper>

      <S.Wrapper>
        <S.ContentWrapper className="carousel-content-wrapper" shadow={shadow}>
          <S.CarouselContent
            className={`show-${slidesVisible}`}
            ref={carouselContent}
            slidesVisible={slidesVisible}
            width={width}
            mobileWidth={mobileWidth}
          >
            {children}
          </S.CarouselContent>
        </S.ContentWrapper>
      </S.Wrapper>
      {showIndicators ? (
        <S.Indicators>
          {children.map((el, i) => (
            <S.Dot
              selected={currentIndex === i}
              onClick={() => scrollTo(i)}
              key={el.key}
            />
          ))}
        </S.Indicators>
      ) : null}
    </S.Container>
  );
};

export default BumpCarousel;
